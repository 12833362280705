.description {
    font-size: 1.4rem;
    font-family: "AssistantR";
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.1);
    text-align: center;
    margin: 1% auto;
    color:white;
    direction: rtl;
    width: 70%;
}

.title {
    font-size: 2rem;
    font-family: "Assistant";
    text-align: center;
    margin: 0 auto 2%;
    color:white;
    direction: rtl;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
}

.row {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin: 2% auto;
    justify-content: space-evenly;
}

/* Responsive design */
@media (max-width: 350px) {
    .description {
        font-size: 1.15rem;
        width: 95%;
        margin: 3% auto;
    }
    .title {
        font-size: 1.8rem;
    }
}

@media (min-width: 350px) and (max-width: 450px) {
    .description {
        font-size: 1.2rem;
        width: 90%;
    }
    .title {
        font-size: 1.9rem;
    }
}

@media (min-width: 450px) and (max-width: 550px) {
    .description {
        font-size: 1.25rem;
        width: 80%;
        margin:2% auto;
    }
}

@media (min-width: 550px) and (max-width: 650px) {
    .description {
        font-size: 1.3rem;
        width: 75%;
        margin:2% auto;
    }
}

@media (min-width: 650px) and (max-width: 750px) {
    .description {
        width: 70%;
        font-size: 1.3rem;
    }
}

@media (min-width: 750px) and (max-width: 850px) {
    .description {
        width: 70%;
    }
}

@media (min-width: 850px) and (max-width: 950px) {
    .description {
        width: 68%;
    }
}

@media (min-width: 950px) and (max-width: 1050px) {
    .description {
        width: 66%;
    }
}

@media (min-width: 1050px) and (max-width: 1150px) {
    .description {
        width: 64%;
    }
}

@media (min-width: 1150px) and (max-width: 1250px) {
    .description {
        width: 62%;
    }
}