.row {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin: 0.5% auto;
    
    justify-content: space-evenly;
}

.title {
    font-size: 4rem;
    font-family: "Assistant";
    text-align: center;
    margin: 2% auto;
    direction: rtl;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2);
    background: linear-gradient(to right, #ffe7e7, #f6a6c3);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    color: transparent;
}

/* Responsive design */
@media (max-width: 350px) {
    .title {
        font-size: 2.5rem;
        margin: 0.6% auto;
    }
    .row {
        margin: 0.3% auto;
    }
}

@media (min-width: 350px) and (max-width: 450px) {
    .title {
        font-size: 2.8rem;
        margin: 0.7% auto;
    }
    .row {
        margin: 0.4% auto;
    }
}

@media (min-width: 450px) and (max-width: 500px) {
    .title {
        font-size: 3rem;
        margin: 0.8% auto;
    }
}

@media (min-width: 501px) and (max-width: 650px) {
    .title {
        font-size: 3.2rem;
        margin: 0.9% auto;
    }
}

@media (min-width: 650px) and (max-width: 750px) {
    .title {
        font-size: 3.4rem;
    }
}

@media (min-width: 750px) and (max-width: 850px) {
    .title {
        font-size: 3.6rem;
    }
}

@media (min-width: 850px) and (max-width: 950px) {
    .title {
        font-size: 3.8rem;
    }
}

@media (min-width: 950px) and (max-width: 1050px) {
    .title {
        font-size: 3.9rem;
    }
}

@media (min-width: 1050px) and (max-width: 1150px) {
    .title {
        font-size: 4rem;
    }
}

@media (min-width: 1150px) and (max-width: 1250px) {
    .title {
        font-size: 4rem;
    }
}