.dumbbell {
  display: flex;
  align-items: center;
  width: 100%;
  max-width: 500px;

  margin: 35px auto;
  position: relative;
}

.circle {
  width: 90px; /* Reduced from 110px */
  height: 90px; /* Reduced from 110px */
  border-radius: 50%;
  background-color: #EAD0CF;
  display: flex;
  box-shadow: 0 0 2px 1px #444;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 2;
}

.circle:first-child {
  left: 0px;
}

.circle:last-child {
  right: 0px;
}

.icon {
  width: 80%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.bar {
  flex: 1;
  background: linear-gradient(to right, #DDADAD, #cc8483, #DDADAD);
  min-height: 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
    direction: rtl;
  align-items: center;
  padding: 10px 70px; /* Reduced horizontal padding */
  margin: 0 20px;
  border-radius: 40px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  z-index: 1;
  text-align: center;
}

.title {
  font-family: 'Assistant';
  font-size: 1.2rem;
  width: 90%;
  margin: 0;
  color: #000000;
}

.description {
  font-family: 'AssistantR';
  font-weight: 400;
  font-size: 1rem;
  width: 90%;
  margin: 5px 0 0;
  color: #000000;
}

@media (max-width: 350px) {
  .circle {
    width: 70px; /* Reduced from 80px */
    height: 70px; /* Reduced from 80px */
  }
  .bar {
    padding: 10px 45px;
    min-height: 50px;
  }
  .title {
    font-size: 0.9rem;
  }
  .description {
    font-size: 0.8rem;
  }
  .dumbbell {
    width: 90%
  }
}

@media (min-width: 350px) and (max-width: 450px) {
  .circle {
    width: 80px; /* Reduced from 90px */
    height: 80px; /* Reduced from 90px */
  }
  .bar {
    padding: 10px 55px;
    min-height: 50px;
  }
  .title {
    font-size: 1rem;
  }
  .description {
    font-size: 0.9rem;
  }
  .dumbbell {
    width: 90%
  }
}

@media (min-width: 450px) and (max-width: 550px) {
  .circle {
    width: 85px; /* Reduced from 100px */
    height: 85px; /* Reduced from 100px */
  }
  .bar {
    padding: 10px 65px;
  }
  .dumbbell {
    width: 90%
  }
}