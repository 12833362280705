.title {
    font-size: 4rem;
    font-family: "Assistant";
    text-align: center;
    margin: 2% auto;
    direction: rtl;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2);
    background: linear-gradient(to right, #ffe7e7, #f6a6c3);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    color: transparent;
}

.description {
    font-family: "AssistantR";
    font-size: 1.2rem;
    text-align: center;
    direction: rtl;
    color: white;
    margin: 0 auto;
    text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.3);
    width: 50%;
    display: flex;
}

.video {
    height: 400px;
    width: auto;
    object-fit: contain;
    border-radius: 10px;
    box-shadow: 0 3px 20px 2px rgba(0, 0, 0, 0.3);
    transition: all 0.3s ease-in-out;
}

.video:hover {
    transform: scale(1.05);
    box-shadow: 0 0 9px 2px rgba(157, 157, 157, 0.5);
}

.center {
    display: flex;
    justify-content: center;
    margin: 2% auto;
}

/* Responsive styles for specific sizes */
@media (max-width: 1250px) and (min-width: 1150px) {
    .title { font-size: 3.8rem; }
    .description { width: 55%; }
    .video { height: 390px; }
}

@media (max-width: 1150px) and (min-width: 1050px) {
    .title { font-size: 3.6rem; }
    .description { width: 60%; }
    .video { height: 380px; }
}

@media (max-width: 1050px) and (min-width: 950px) {
    .title { font-size: 3.4rem; }
    .description { width: 65%; }
    .video { height: 370px; }
}

@media (max-width: 950px) and (min-width: 850px) {
    .title { font-size: 3.2rem; }
    .description { width: 70%; }
    .video { height: 360px; }
}

@media (max-width: 850px) and (min-width: 750px) {
    .title { font-size: 3rem; }
    .description { width: 75%; font-size: 1.1rem; }
    .video { height: 350px; }
}

@media (max-width: 750px) and (min-width: 650px) {
    .title { font-size: 2.8rem; }
    .description { width: 80%; font-size: 1rem; }
    .video { height: 340px; }
}

@media (max-width: 650px) and (min-width: 550px) {
    .title { font-size: 2.6rem; }
    .description { width: 85%; }
    .video { height: 330px; }
}

@media (max-width: 550px) and (min-width: 450px) {
    .title { font-size: 2.4rem; }
    .description { width: 90%; font-size: 0.9rem; }
    .video { height: 320px; }
}

@media (max-width: 450px) and (min-width: 350px) {
    .title { font-size: 2.2rem; }
    .description { width: 95%; font-size: 0.85rem; }
    .video { height: 310px; }
}

@media (max-width: 350px) {
    .title { font-size: 2rem; }
    .description { width: 100%; font-size: 0.8rem; }
    .video { height: 300px; }
}