.sliderContainer {

    width:80%;
    margin: auto;
    height:auto;
    justify-content: center;
    align-items: center;
  }
  .slick-dots {
    color: var(--slick-dots-color, inherit);
  }

  .slick-dots li button {
    color: var(--slick-dots-color, inherit);
  }
  
  .center{
    display: flex;
    width:100%;
    justify-content: center;
  }
  .centered{
    display: flex;
    width:50%;
    margin:2% auto;
    justify-content: center;
  }
  .imaged{
    width:100%;
    object-fit: contain;
  }
  .image{
    width:50%;
    height:50%;
  }
  .image1{
    width:100%;
    height:50%;
    object-fit: cover;
  }
  .title{
    font-size:3.5rem;
    direction: rtl;
    width:85%;
    text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2);
    color:white;
    justify-content: center;
    text-align: center;
    margin:2% auto;
    font-family: "Assistant";
    font-weight: bold;
}
.explain{
    font-size: 25px;
    direction: rtl;
    width:65%;
    color:white;
    justify-content: center;
    text-align: center;
    margin: 2% auto;
    font-family: "AssistantR";
}
.video{

    height:600px;
    width:400px;
    display: flex;
    margin:5% auto;
    object-fit: cover;
}
.center{
margin:2% auto;
  display: flex;
  justify-content: center;
}

@media only screen and (max-width: 350px) {

    .sliderContainer {

        width: 80%;
        margin: auto;
position: relative;
height: auto;

        justify-content: center;
        align-items: center;
      }
      .slick-dots {
        color: var(--slick-dots-color, inherit);
      }
      
      .slick-dots li button {
        color: var(--slick-dots-color, inherit);
      }
      .centered{
        display: flex;
        width:95%;
        margin:2% auto;
        justify-content: center;
      }
      .center{
        display: flex;
        width:100%;
        justify-content: center;
      }
      .image{
        width:100%;
        height:100%;
      
      }
      .image1{
        width:100%;
        height:100%;
        object-fit: cover;
      }
      .title{
        font-size: 2rem;
        direction: rtl;
        width:95%;
        color:white;
      
        justify-content: center;
        text-align: center;
        margin:3% auto;
        font-family: "Assistant";
        font-weight: bold;
    }
    .explain{
        font-size: 18px;
        direction: rtl;
        width:90%;
        color:white;
        justify-content: center;
        text-align: center;
        margin: 5% auto;
        font-family: "AssistantR";
    }
    .video{
    
        height:400px;
        width:250px;
        display: flex;
        margin:8% auto;
        object-fit: cover;
    }
}
@media only screen and (min-width: 350px) and (max-width: 450px) {

    .sliderContainer {
        width: 75%;
        margin: auto;
        height: auto;
        
        justify-content: center;
        align-items: center;
      }
      .slick-dots {
        color: var(--slick-dots-color, inherit);
      }
      
      .slick-dots li button {
        color: var(--slick-dots-color, inherit);
      }
      .centered{
        display: flex;
        width:90%;
        margin:2% auto;
        justify-content: center;
      }
      .center{
        display: flex;
        width:100%;
        justify-content: center;
      }
      .image{
        width:100%;
        height:100%;
      
      }
      .image1{
        width:100%;
        height:100%;
        object-fit: cover;
      }
      .title{
        font-size: 2rem;
        direction: rtl;
        width:90%;
        color:white;
        justify-content: center;
        text-align: center;
        margin:2% auto;
        font-family: "Assistant";
        font-weight: bold;
    }
    .explain{
        font-size: 20px;
        direction: rtl;
        width:90%;
        color:white;
        justify-content: center;
        text-align: center;
        margin: 5% auto;
        font-family: "AssistantR";
    }
    .video{
    
        height:450px;
        width:300px;
        display: flex;
        margin:8% auto;
        object-fit: cover;
    }
}
@media only screen and (min-width: 450px) and (max-width: 550px) {

    .sliderContainer {

        width: 85%;
        margin: auto;
    
        justify-content: center;
        align-items: center;
      }
      .slick-dots {
        color: var(--slick-dots-color, inherit);
      }
      
      .slick-dots li button {
        color: var(--slick-dots-color, inherit);
      }
      .centered{
        display: flex;
        width:80%;
        margin:2% auto;
        justify-content: center;
      }
      .center{
        display: flex;
        width:100%;
        justify-content: center;
      }
      .image{
        width:100%;
        height:100%;
      
      }
      .image1{
        width:100%;
        height:100%;
        object-fit: cover;
      }
      .title{
        font-size: 2.5rem;
        direction: rtl;
        width:85%;
        color:white;
        justify-content: center;
        text-align: center;
        margin:2% auto;
        font-family: "Assistant";
        font-weight: bold;
    }
    .explain{
        font-size: 20px;
        direction: rtl;
        width:90%;
        color:white;
        justify-content: center;
        text-align: center;
        margin: 5% auto;
        font-family: "AssistantR";
    }
    .video{
    
        height:450px;
        width:350px;
        display: flex;
        margin:8% auto;
        object-fit: cover;
    }
}
@media only screen and (min-width: 550px) and (max-width: 650px) {

    .sliderContainer {

        width: 85%;
        margin: auto;
    
        justify-content: center;
        align-items: center;
      }
      .slick-dots {
        color: var(--slick-dots-color, inherit);
      }
      
      .slick-dots li button {
        color: var(--slick-dots-color, inherit);
      }
      .centered{
        display: flex;
        width:80%;
        margin:2% auto;
        justify-content: center;
      }
      .center{
        display: flex;
        width:100%;
        justify-content: center;
      }
      .image{
        width:90%;
        height:100%;
      
      }
      .image1{
        width:100%;
        height:100%;
        object-fit: cover;
      }
      .title{
        font-size:2.5rem;
        direction: rtl;
        width:85%;
        color:white;
        justify-content: center;
        text-align: center;
        margin:2% auto;
        font-family: "Assistant";
        font-weight: bold;
    }
    .explain{
        font-size: 22px;
        direction: rtl;
        width:80%;
        color:white;
        justify-content: center;
        text-align: center;
        margin: 5% auto;
        font-family: "AssistantR";
    }
    .video{
    
        height:500px;
        width:400px;
        display: flex;
        margin:8% auto;
        object-fit: cover;
    }
}
@media only screen and (min-width: 650px) and (max-width: 750px) {

    .sliderContainer {

        width: 80%;
        margin: auto;
    
        justify-content: center;
        align-items: center;
      }
      .slick-dots {
        color: var(--slick-dots-color, inherit);
      }
      
      .slick-dots li button {
        color: var(--slick-dots-color, inherit);
      }
      .centered{
        display: flex;
        width:75%;
        margin:2% auto;
        justify-content: center;
      }
      .center{
        display: flex;
        width:100%;
        justify-content: center;
      }
      .image{
        width:90%;
        height:100%;
      
      }
      .image1{
        width:100%;
        height:100%;
        object-fit: cover;
      }
      .title{
        font-size: 2.5rem;
        direction: rtl;
        width:85%;
        color:white;
        justify-content: center;
        text-align: center;
        margin:2% auto;
        font-family: "Assistant";
        font-weight: bold;
    }
    .explain{
        font-size: 22px;
        direction: rtl;
        width:80%;
        color:white;
        justify-content: center;
        text-align: center;
        margin: 5% auto;
        font-family: "AssistantR";
    }
    .video{
    
        height:500px;
        width:400px;
        display: flex;
        margin:8% auto;
        object-fit: cover;
    }
}
@media only screen and (min-width: 750px) and (max-width: 850px) {

    .sliderContainer {

        width: 75%;
        margin: auto;
    
        justify-content: center;
        align-items: center;
      }
      .slick-dots {
        color: var(--slick-dots-color, inherit);
      }
      
      .slick-dots li button {
        color: var(--slick-dots-color, inherit);
      }
      .centered{
        display: flex;
        width:70%;
        margin:2% auto;
        justify-content: center;
      }
      .center{
        display: flex;
        width:100%;
        justify-content: center;
      }
      .image{
        width:90%;
        height:100%;
      
      }
      .image1{
        width:100%;
        height:100%;
        object-fit: cover;
      }
      .title{
        font-size: 2.8rem;
        direction: rtl;
        width:75%;
        color:white;
        justify-content: center;
        text-align: center;
        margin:2% auto;
        font-family: "Assistant";
        font-weight: bold;
    }
    .explain{
        font-size: 22px;
        direction: rtl;
        width:80%;
        color:white;
        justify-content: center;
        text-align: center;
        margin: 5% auto;
        font-family: "AssistantR";
    }
    .video{
    
        height:500px;
        width:400px;
        display: flex;
        margin:8% auto;
        object-fit: cover;
    }
}
@media only screen and (min-width: 850px) and (max-width: 950px) {

    .sliderContainer {

        width: 85%;
        margin: auto;
    
        justify-content: center;
        align-items: center;
      }
      .slick-dots {
        color: var(--slick-dots-color, inherit);
      }
      
      .slick-dots li button {
        color: var(--slick-dots-color, inherit);
      }
      .centered{
        display: flex;
        width:70%;
        margin:2% auto;
        justify-content: center;
      }
      .center{
        display: flex;
        width:100%;
        justify-content: center;
      }
      .image{
        width:85%;
        height:100%;
      
      }
      .image1{
        width:100%;
        height:100%;
        object-fit: cover;
      }
      .title{
        font-size:3rem;
        direction: rtl;
        width:85%;
        color:white;
        justify-content: center;
        text-align: center;
        margin:2% auto;
        font-family: "Assistant";
        font-weight: bold;
    }
    .explain{
        font-size: 22px;
        direction: rtl;
        width:80%;
        color:white;
        justify-content: center;
        text-align: center;
        margin: 5% auto;
        font-family: "AssistantR";
    }
    .video{
    
        height:600px;
        width:450px;
        display: flex;
        margin:8% auto;
        object-fit: cover;
    }
}
@media only screen and (min-width: 950px) and (max-width: 1050px) {

    .sliderContainer {

        width: 75%;
        margin: auto;
    
        justify-content: center;
        align-items: center;
      }
      .slick-dots {
        color: var(--slick-dots-color, inherit);
      }
      
      .slick-dots li button {
        color: var(--slick-dots-color, inherit);
      }
      
      .center{
        display: flex;
        width:100%;
        justify-content: center;
      }
      .image{
        width:85%;
        height:100%;
      
      }
      .image1{
        width:100%;
        height:100%;
        object-fit: cover;
      }
      .title{
        font-size: 3rem;
        direction: rtl;
        width:75%;
        color:white;
        justify-content: center;
        text-align: center;
        margin:2% auto;
        font-family: "Assistant";
        font-weight: bold;
    }
    .explain{
        font-size: 22px;
        direction: rtl;
        width:80%;
        color:white;
        justify-content: center;
        text-align: center;
        margin: 5% auto;
        font-family: "AssistantR";
    }
    .video{
    
        height:600px;
        width:450px;
        display: flex;
        margin:8% auto;
        object-fit: cover;
    }
}
@media only screen and (min-width: 1050px) and (max-width: 1250px) {

    .sliderContainer {

        width: 90%;
        margin: auto;
    
        justify-content: center;
        align-items: center;
      }
      .slick-dots {
        color: var(--slick-dots-color, inherit);
      }
      
      .slick-dots li button {
        color: var(--slick-dots-color, inherit);
      }
      
      .center{
        display: flex;
        width:100%;
        justify-content: center;
      }
      .image{
        width:80%;
        height:100%;
      
      }
      .image1{
        width:100%;
        height:100%;
        object-fit: cover;
      }
      .title{
        font-size: 3.5rem;
        direction: rtl;
        width:75%;
        color:white;
        justify-content: center;
        text-align: center;
        margin:2% auto;
        font-family: "Assistant";
        font-weight: bold;
    }
    .explain{
        font-size: 22px;
        direction: rtl;
        width:75%;
        color:white;
        justify-content: center;
        text-align: center;
        margin: 3% auto;
        font-family: "AssistantR";
    }
    .video{
    
        height:600px;
        width:450px;
        display: flex;
        margin:8% auto;
        object-fit: cover;
    }
}